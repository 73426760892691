nav.main_menu,
.title_outer,
.two_columns_25_75 > .column1,
header.page_header form.search,
.search-wrapper,
footer,
.header_top{
    display: none;
}

.header_bottom .container_inner{
    height: 9em;
}

.page-template-general-content .column2.copy{
    border-left: none;
    padding-left: 0;
}
.header_top{

}